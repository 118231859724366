<template>
  <b-modal
    :title="$gettext('Update Team')"
    :cancel-title="$gettext('Cancel')"
    :ok-title="$gettext('Save')"
    @ok="handleUpdateTeam"
    id="update-team-modal"
    hide-header-close
  >
    <form @submit.prevent="handleUpdateTeam">
      <FormInput
        v-model="name"
        :label="$gettext('Team name')"
        field="name"
        required
      />
    </form>
  </b-modal>
</template>
<script>
import FormInput from "@/components/FormGroups/FormInput";
import { mapActions } from "vuex";
export default {
  components: { FormInput },
  props: {
    teamId: {
      type: [Number, String],
      required: true
    },
    teamName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      name: ""
    };
  },
  methods: {
    ...mapActions("teams", ["updateTeam"]),
    ...mapActions("form", ["submit"]),
    handleUpdateTeam(event) {
      event.preventDefault();
      const payload = {
        name: this.name
      };
      this.submit(() =>
        this.updateTeam({
          id: this.teamId,
          ...payload
        }).then(() => {
          this.$bvModal.hide("update-team-modal");
        })
      );
    }
  },
  watch: {
    teamName(newValue) {
      this.name = newValue;
    }
  }
};
</script>
