import { render, staticRenderFns } from "./UpdateTeamModal.vue?vue&type=template&id=5324d81b&"
import script from "./UpdateTeamModal.vue?vue&type=script&lang=js&"
export * from "./UpdateTeamModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports