<template>
  <div class="row">
    <UpdateTeamModal
      :team-id="teamUpdating.id"
      :team-name="teamUpdating.name"
    />
    <div class="col-md-12">
      <ContentHeader :title="$gettext('My Teams')" />
      <AdvancedTable store="teams" :fields="fields" :searchable="false">
        <template #cell(name)="{item}">
          {{ item.name }}
          <i
            v-if="isCurrentTeam(item.id)"
            class="fas fa-check text-success ml-2"
            v-b-tooltip.hover
            :title="$gettext('Your current team')"
          ></i>
        </template>
        <template #cell(created_at)="{item}">
          <FormattedDate :date="item.created_at" />
        </template>
        <template #cell(updated_at)="{item}">
          <FormattedDate :date="item.updated_at" />
        </template>
        <template #cell(actions)="{item}">
          <b-button
            @click="handleEdit(item)"
            variant="warning"
            class="btn btn-icon btn-sm mr-1"
            :disabled="!isTeamOwner(item.owner_id)"
            v-b-tooltip.hover
            :title="$gettext('Edit')"
            ><i class="fa fa-edit"></i
          ></b-button>
          <b-button
            v-if="!isCurrentTeam(item.id)"
            @click="handleTeamClick(item.id)"
            variant="info"
            class="btn btn-icon btn-sm"
            v-b-tooltip.hover
            :title="$gettext('Switch current team')"
            ><i class="fas fa-exchange-alt"></i
          ></b-button>
        </template>
      </AdvancedTable>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ContentHeader from "@/components/ContentHeader";
import AdvancedTable from "@/components/AdvancedTable";
import FormattedDate from "@/components/FormattedDate";
import UpdateTeamModal from "@/components/Teams/UpdateTeamModal";
import { VBTooltip } from "bootstrap-vue";
Vue.directive("b-tooltip", VBTooltip);
export default {
  components: {
    FormattedDate,
    ContentHeader,
    AdvancedTable,
    UpdateTeamModal
  },
  data() {
    return {
      teamUpdating: {
        id: "",
        name: ""
      }
    };
  },
  computed: {
    ...mapGetters("teams", { teams: "items" }),
    ...mapGetters("me", ["user"]),
    fields() {
      return [
        { key: "name", label: this.$gettext("Name") },
        { key: "created_at", label: this.$gettext("created_at") },
        { key: "updated_at", label: this.$gettext("updated_at") },
        {
          key: "actions",
          tdClass: "td-actions-2",
          label: this.$gettext("actions")
        }
      ];
    }
  },
  methods: {
    ...mapActions("me", ["switchTeam"]),
    handleTeamClick(teamId) {
      this.switchTeam(teamId);
    },
    handleEdit(team) {
      this.teamUpdating = team;
      this.$bvModal.show("update-team-modal");
    },
    isCurrentTeam(teamId) {
      return this.user.team_id === teamId;
    },
    isTeamOwner(teamOwnerId) {
      return this.user.id === teamOwnerId;
    }
  }
};
</script>
